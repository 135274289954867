import React, { useEffect, useState } from 'react';
import httpService from '../services/httpService';
import HeaderComp from '../components/Header/Header';
import FooterComp from '../components/Footer/Footer';
import SearchPage from '../components/Search/SearchPage';
import config from '../config/config';
import SetHeaders from '../config/SetHeaders';
import { connect } from 'react-redux';
import seo from '../config/seo';
import Head from 'next/head';


const SearchContainer = ({ match, history, showLoader, hideLoader }: any) => {

    const [state, setstate]: any = useState({
        Header: null,
        Body: null,
        Footer: null
    });

    const [seoTitle, setSeoTitle]: any = useState();

    useEffect(() => {
        showLoader();
        const getData = () => {
            setSeoTitle(seo.pageNotFound);
            const request = {
                url: `${config.endpoints.notFound}`,
                method: 'get'
            };
            showLoader();
            httpService.invokeApi(request, (success: boolean, response: any) => {
                if (success) {
                    setstate({
                        Header: response.Header ? response.Header : null,
                        Body: response,
                        Footer: response.Footer ? response.Footer.Footer : null
                    });
                    hideLoader();
                } else {
                    hideLoader();
                }
            });
        };
        getData();
    }, [history, showLoader, hideLoader]);
    return (
        <React.Fragment>
            {state.Body && !state.Body.Seo && (
                <Head>
                    <title>{seoTitle}</title>
                </Head>
            )}
            {state.Body && <SetHeaders HEADERS={state.Body} />}
            {state.Header && <HeaderComp HeaderValue={state.Header} />}
            {state.Body && (
                <div className="app-body p-b-5p p-t-3p">
                    <SearchPage history={history} match={match} data={state.Body} location={location} />
                </div>
            )}
            {state.Footer && <FooterComp data={state.Footer} />}
        </React.Fragment>
    );
};

const mapState = (state: any) => {
    return {};
};

const mapDispatch = (dispatch: any) => {
    return {
        showLoader: () => dispatch({ type: 'LOADER_SHOW', isLoadingPage: true }),
        hideLoader: () => dispatch({ type: 'LOADER_HIDE' })
    };
};

export default connect(
    mapState,
    mapDispatch
)(SearchContainer);
